import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const sbsgSlice = createApi({
  reducerPath: 'sbsgSlice',
  baseQuery: fetchBaseQuery({
    baseUrl: import.meta.env.VITE_BASE,
    prepareHeaders: (headers, { getState }) => {
      const token = getState().token.value.token;
      if (token) {
        headers.set('authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: ['GET_SBSG'],
  endpoints: (builder) => ({
    sbsgPreview: builder.query({
      query: ({ taskId }) => `sbsgs/learners/tasks/${taskId}`,
      providesTags: ['GET_SBSG'],
    }),
  }),
});

export const { useSbsgPreviewQuery } = sbsgSlice;
