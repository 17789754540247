import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    firstName: '',
    lastName: '',
    email: '',
    isEmail: '',
    role: '',
    phoneNumber: '',
    countryCode: '',
    password: '',
  },
};

export const signupSlice = createSlice({
  name: 'signup',
  initialState,
  reducers: {
    setSignupData: (state, item) => {
      state.value = item.payload;
    },
  },
});

export const { setSignupData } = signupSlice.actions;

export default signupSlice.reducer;
